import React from "react"
import Layout from "../layout/master"

const ContactPage = () => (
  <Layout>
    <section id="contact" className="section section-page section-contact section-margin ">
      <div className="section-cover"></div>
      <div className="section-header align-x-center">
        <div className="h-content width-medium">
          <h2 className="h-title font-title sr-up-1">
            Reach out
          </h2>
          <a className="btn btn-line-a px-0 py-0 mt-btn sr-up-2" href="mailto://edkaricho@gmail.com">
            <span className="text text-uppercase">Mail me</span>
            <span className="icon icon-menu icon-arrow-a icon-anim">
              <span className="arrow-right"></span>
            </span>
          </a>
        </div>
      </div>

      <div className="section-content align-x-center">
        <div className="c-wrapper width-medium">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="content-text sr-up-3">
                <p>
                  If you have any questions about me, want to hire me, or even just kick back with me,
                </p>
                <p>
                  <a className="contact-email" href="mailto://contact@hello.com">edkaricho@gmail.com</a>
                </p>
                <p>
                  <span className="contact-phone">+245 710 717 428</span>
                </p>
                <p>
                  <span className="contact-github">Github</span>
                </p>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="form-container form-container-contact sr-up-4">
                <form className="send_message_form message form" method="post"
                      id="message_form" noValidate="novalidate">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group name">
                        <input id="mes-name" name="name" type="text" placeholder="Name"
                               className="form-control form-control-outline input-full thick form-success-clean"
                               required
                               aria-required="true"/>
                      </div>
                      <div className="form-group email">
                        <input id="mes-email" type="email" placeholder="Email address" name="email"
                               className="form-control form-control-outline input-full thick form-success-clean"
                               required
                               aria-required="true"/>
                      </div>
                      <div className="form-group no-border">
                        <textarea id="mes-text" placeholder="Your message ..." name="message"
                                  className="form-control form-control-outline thick form-success-clean" required
                                  aria-required="true"></textarea>

                      </div>
                    </div>
                    <div className="col-12">
                      <button id="submit-message" className="btn btn-line-a email_b" name="submit_message">
                        <span className="text text-uppercase">Send Now</span>
                        <span className="icon icon-menu icon-arrow-a icon-anim">
                          <span className="arrow-right"></span>
                        </span>
                      </button>
                    </div>
                    <div>
                      <p className="form-text-feedback form-success-visible gone">
                        Your message has been sent, thank you.
                      </p>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-footer"></div>
    </section>
  </Layout>

)

export default ContactPage
